<template>
<div id="app">   
  <CRow>
    <CCol sm="8" md="8">
        <CCard>
          <CCardHeader>
            <CRow>
              <CCol col="7" class="text-left"><h4>Edit Tier</h4></CCol> 
              <CCol col="4" class="text-left">
                    <b-button-group>
                       <router-link to="/tier" tag="b-button"> << Back </router-link>  
                       <b-button style="width:80px;" v-on:click="actionUpdate()" variant="primary">Update</b-button>                  
                    </b-button-group>
              </CCol> 
            </CRow>     
          </CCardHeader>
          <CCardBody>               

            <CForm>

              <CInput
                  type="text" 
                  label="Tier Code"
                  v-model="code"
                  placeholder="Tier Name" 
                  :disabled="!isDisabled"                              
                  horizontal                
              /> 

              <CInput
                  type="text" 
                  label="Tier Name"
                  v-model="name"
                  placeholder="Type Tier Name"                            
                  horizontal                
              />

              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Coin Min Limit</label>
                  </b-col>   
                  <b-col sm="4"> 
                      <b-form-input type="number" min=0 step="any" v-model="coinMinLimit" ></b-form-input>
                  </b-col>
                  <b-col sm="1">                    
                     <label for="input-small">Max.</label>
                  </b-col>   
                  <b-col sm="4"> 
                      <b-form-input type="number" min=0 step="any" v-model="coinMaxLimit" ></b-form-input>
                  </b-col>
              </b-row><br/> 
               
              <CTextarea
                    label="Description"
                    v-model="description"  
                    placeholder="Type Description..."
                    maxlength="180"  
                    description="Maximum 180 characters"
                    horizontal
                    rows="2"               
              />

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div><br/>

              <CRow>
                <CCol col="12" class="text-left">                   
                  <CAlert v-bind:color="col" v-if="seen" id="alert">{{ msg }}</CAlert>
                </CCol> 
              </CRow> 

              <CRow>
                <CCol col="12" class="text-left"><h5>Themes</h5></CCol>               
              </CRow>              

              <CInput
                  type="text" 
                  label="Name"
                  v-model="thName"
                  placeholder="Name " 
                  horizontal                
              /> 

               <div class="form-group">                        
                  <b-row>
                    <b-col sm="3">
                        <label for="input-small" >Type</label>
                    </b-col>
                    <b-col sm="9">
                        <b-form-select v-model="thType" @change="onSelectChange(thType)" :options="optType"></b-form-select>
                    </b-col>                             
                  </b-row>               
               </div>             

                <b-row v-if="seen_style">                  
                  <b-col sm="3">                    
                     <label for="input-small" >Style:</label>
                  </b-col>   
                  <b-col sm="9"> 
                    <multiselect v-model="fontStyle" :options="optFontStyle" :multiple="true" :close-on-select="false" :clear-on-select="false" :preserve-search="true" placeholder="Pick Some" label="name" track-by="name" :preselect-first="true">
                    <template slot="selection1" slot-scope="{ values, search, isOpen }"><span class="multiselect__single" v-if="values.length &amp;&amp; !isOpen">{{ values.length }} options selected</span></template>
                    </multiselect>
                  </b-col>
                </b-row><br/>      
              
              <div v-if="seen_prop" class="form-group">                        
                  <b-row>
                    <b-col sm="3">
                        <label for="input-small" >Select Font :</label>
                    </b-col>
                    <b-col sm="9">                        
                        <b-form-select v-model="prop" :options="optfont"></b-form-select>
                    </b-col>                             
                  </b-row>               
              </div>

              <div v-if="seen_color" class="form-group">                        
                  <b-row>
                    <b-col sm="3">
                        <label for="input-small" >Color</label>
                    </b-col>
                    <b-col sm="9">                        
                        <v-input-colorpicker v-model="color" />
                    </b-col>                             
                  </b-row>               
              </div>

              <CRow>
                <CCol col="3" class="text-left"><h4></h4></CCol> 
                <CCol col="9" class="text-left">
                      <b-button-group>
                      <b-button style="width:80px;" v-if="seenBtnCcl" v-on:click="actionCancel()">Cancel</b-button>
                      <b-button style="width:80px;" v-if="seenBtnAdd" v-on:click="actionAdd()" variant="info">Add List</b-button>
                      <b-button style="width:80px;" v-if="seenBtnUpdate" v-on:click="actionUpdateList()" variant="warning">Update List</b-button>                     
                      </b-button-group>
                </CCol> 
              </CRow><br/>                    

                <b-row>              
                    <div class="table-responsive table">
                      <b-table striped hover  :items="items" :fields="fields" :current-page="currentPage" :per-page="perPage" :filter="filter">   

                        <template v-slot:cell(actions)="{ item }"> 
                            <b-button variant="info" @click="onClickEdited(item.name,item.type)"  >Edit</b-button>&nbsp;
                            <b-button variant="danger" @click="onClickRemoved(item.name, item.type)"  >Delete</b-button>
                        </template>            

                      </b-table>
                    </div>
                </b-row><br/>

                <b-row>
                    <b-col sm="3">
                      <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
                    </b-col>       
                    <b-col sm="9">
                      <b-form-select :options="[{text:5,value:5},{text:10,value:10},{text:15,value:15}]" v-model="perPage" style="width:100px;">
                      </b-form-select>
                    </b-col>
                </b-row>                         
             
           </CForm>
          </CCardBody>
        </CCard>
      </CCol>
     </CRow>

</div>
</template>

<script>
 import AccountService from '@/api/AccountService.js';  
 import InputColorPicker from 'vue-native-color-picker';
 import Multiselect from 'vue-multiselect';
 import font from '@/assets/fontfamilies.json';
 import moment from 'moment';
 
  export default { 
    name: 'edit_tier',
    components: { 
      'v-input-colorpicker': InputColorPicker,Multiselect
    },     
    data() { var date = new Date();
      return { 
          id : this.$route.params.id,
          seen_style:false,
          seen_prop:false,
          seen_color:false,
          thName:'',
          prop:[],
          color:'',
          thType:0,
          typeText:'', 
          editName : '',  
          optfont : font.families,       
          optType:[
            {'value' : 0 , 'text' : '-- Select Type --'},
            {'value' : 1 , 'text' : 'color'},
            {'value' : 2 , 'text' : 'style'},
            //{'value' : 3 , 'text' : 'font-weight'},
            {'value' : 3 , 'text' : 'font-family'},
          ],
          fontStyle:[],
          optFontStyle:[
            {'name': 'Normal'},
            {'name': 'Italic'},
            {'name': 'Bold'}, 
          ],   
          keylist:[],
          typeList:[],     
          items:[],
          itemstemp:[],
          fields: [   
            {
              key: 'name',
              label : 'Name',
              sortable: true
            },  
            {
              key: 'type',
              label : 'Type',
              sortable: true
            },  
            {
              key: 'prop',
              label : 'Properties',
              sortable: true
            },  
            {            
              key: 'actions',
              label: 'Actions'         
            } 
          ],       
          currentPage: 1,
          perPage: 5,
          filter: null,
          isDisabled:false, 
          seenBtnUpdate:false,
          seenBtnAdd:true,    
          seenBtnCcl:false,    
          spinner:false,           
          code:'',
          name:'',  
          coinMaxLimit:0,
          coinMinLimit:0,        
          description:'',           
          status:'',
          seen:false,          
          msg :'',
          col:''
        };      
    },
    created () {       
       this.getUserTierById(this.id);
    },
    methods: { 

      actionCancel(){

         this.seenBtnCcl = false;
         this.seenBtnUpdate = false;
         this.seenBtnAdd = true;

      },
      
      onClickRemoved(name){

        var temp1 = this.keylist;
        let index1 = temp1.findIndex( temp1 => temp1 === name );
        if (index1 > -1) {  this.keylist.splice(index1, 1); }

        var temp2  = this.items;
        let index2 = temp2.findIndex( temp2 => temp2.name === name );
        if (index2 > -1) {  this.items.splice(index2, 1); }         

      },     

      onClickEdited(name,type){   //console.log('name:'+name+' | type: '+type);

          this.seenBtnUpdate = true;  this.editName = name;
          this.seenBtnAdd = false; this.seenBtnCcl = true;

          var temp4  = this.optType; 
          let index4 = temp4.findIndex( temp4 => temp4.text === type );  //console.log('index is :'+index4); 
          this.onSelectChange(index4);   

          var temp  = this.items; 
          let index = temp.findIndex( temp => temp.name === name );
          var src   = this.items[index];


          this.thName = src.name;  
          var temp2   = this.optType;
          let index2  = temp2.findIndex( temp2 => temp2.text === src.type );
          this.thType = temp2[index2].value;

          //console.log(src.prop);
          var strProp = src.prop;  this.fontStyle = [];
          if(type === "color"){
              var left = strProp.split(":");
              this.color = left[1];    console.log('color :' + strProp);  
          } else if(type === "font-family"){
              var left = strProp.split(":");
              var pname   = left[1];  console.log('font-family :' +pname); 
              var srclist = this.optfont;
              let index3  = srclist.findIndex( srclist => srclist === pname );
              console.log(this.optfont[index3]);
              this.prop = this.optfont[index3];

          } else if(strProp.includes(",")){
              var left = strProp.split(":");
              var list = left[1].split(","); console.log('multi style :' +list); 
              for (var key in list) {
                var pname = list[key]; 
                var srclist = this.optFontStyle;
                let index3 = srclist.findIndex( srclist => srclist.name === pname );
                this.fontStyle.push(this.optFontStyle[index3]);
              } 
          } else {
              var left    = strProp.split(":");
              var pname   = left[1];  console.log('single style :' +pname); 
              var srclist = this.optFontStyle;
              let index3  = srclist.findIndex( srclist => srclist.name === pname );
              this.fontStyle.push(this.optFontStyle[index3]);

              
          }          
      },

      actionAdd(){  //console.log(this.thType);    
         let item = {}; var list = []; var except = [1,3];
         if(this.thName && this.thType !== 0 ){
            var name  = this.thName;   
            if(!except.includes(this.thType)){      
              var style = this.fontStyle;
              for (var key in style) { 
                var val = style[key].name; list.push(val);
              }
            }

            switch(this.thType) {
            case 1: var prop = (!this.color)? "black" : this.color; break;
            case 3: var prop = this.prop;  break;    
            default: var prop = list.join(","); }

            if(this.keylist.includes(this.thName)){
                this.msg   = "Key name already exist !"; 
                this.col = 'warning'; this.seen  = true;           
            } else if(prop === ''){
                this.msg   = "Style can't empty !"; 
                this.col = 'warning'; this.seen  = true;
            } else {  //console.log(this.typeText);
              item['name'] = name;           this.keylist.push(name);
              item['type'] = this.typeText;  //this.typeList.push(this.typeText);
              item['prop'] = this.typeText+':'+prop;      this.items.push(item);
            }
         } else {
            this.msg   = "All fields are required !"; 
            this.col   = 'warning'; this.seen  = true;           
         }  
        
         setTimeout( () =>  this.seen=false , 5000);

      },
      
      actionUpdateList(){     
         var list = []; var except = [1,3];

         if(this.thName && this.thType !== 0 ){    

            if(!except.includes(this.thType)){      
              var style = this.fontStyle;
              for (var key in style) { var val = style[key].name; list.push(val); }
            }

            switch(this.thType) {
              case 1: var prop = (!this.color)? "black" : this.color; break;
              case 3: var prop = this.prop;  break;    
              default: var prop = list.join(","); }

            if(prop === ''){
                this.msg   = "Style can't empty !"; 
                this.col = 'warning'; this.seen  = true;
            } else {               
              // var name  = this.editName;  var temp  = this.items; 
              // let index = temp.findIndex( temp => temp.name === name ); 

              // console.log(this.items[index]);

              // temp[index].name = name;
              // temp[index].type = this.typeText;
              // temp[index].prop = this.typeText+':'+prop;

              var name     = this.editName;  let item = {};
              this.onClickRemoved(name);

              item['name'] = name;                    this.keylist.push(name);
              item['type'] = this.typeText;  
              item['prop'] = this.typeText+':'+prop;   this.items.push(item);             

              //this.actionUpdate();
              //console.log(this.items[index]);
              
              this.seenBtnUpdate = false; this.seenBtnAdd = true;
            }
         } else {
            this.msg   = "All fields are required !"; 
            this.col   = 'warning'; this.seen  = true;           
         }  
        
         setTimeout( () =>  this.seen=false , 5000);

      },      
      
      onSelectChange(select){ 

        switch(select) {
          case 1:       
            this.seen_color = true; 
            this.seen_style = false;
            this.seen_prop  = false;
            this.typeText = 'color';
            break;
          case 2:         
            this.seen_style = true;
            this.seen_color = false;
            this.seen_prop  = false;
            this.fontStyle  = '';        
            this.typeText = 'style';
            break;          
          case 3:          
            this.seen_prop = true;
            this.seen_style = false;
            this.seen_color = false;
            this.typeText = 'font-family';
            break;
          default:          
            this.seen_color = false; 
            this.seen_style = false;
            this.seen_prop = false;
            this.typeText = '';
        }        

      },  

      actionUpdate(){   
        this.spinner = true;   
        let params = {}; let theme = {};  var validate = [];

        if(this.items){
          var srcTheme = this.items;
          for (var key in srcTheme) { 
              var row = srcTheme[key];
              theme[row.name] = row.prop;
          }
        }

        console.log(theme);
        
        params['coinMaxLimit'] = this.coinMaxLimit;
        params['coinMinLimit'] = this.coinMinLimit;
        params['description']  = this.description; 
        params['name']  = this.name; 
          // theme['accentColor']       = ""; 
          // theme['buttonTextColor']   = "";  
          // theme['primaryColor']      = "";        
        params['theme'] = theme;
        
        var checkKeys = ["name","description"];
        for (var key in params) {
          if(checkKeys.includes(key)){  
            if(!params[key]) { validate.push(false); } else { validate.push(true); }
          }
        }

        if(!validate.includes(false)){   console.log(params); 
            this.updateUserTier(this.id,params);            
        } else {
           this.msg   = 'Data Not Complete!'; 
           this.color = 'warning'; this.seen  = true;  
           setTimeout( () =>  this.seen=false , 5000);
        } 
        
        this.spinner = false;

      }, 

      getUserTierById: function(id) {
        AccountService.getUserTierById(id).then(resp => {  //console.log(resp);
             this.code = resp.code;
             this.name = resp.name;
             this.description = resp.description;
             this.body = resp.body; 
             this.coinMaxLimit = resp.coinMaxLimit;
             this.coinMinLimit = resp.coinMinLimit;

             var data = resp.theme; let item = {}; var list = [];
             for (var key in data) { 
                var row = data[key].split(":"); //console.log(row);
                 item['name'] = key;            this.keylist.push(key);
                 item['type'] = row[0];         //this.typeList.push(row[0]);
                 item['prop']  = row[0]+':'+row[1];           
                 list.push(item); item = []; 
             }

             this.items = list;
             
             
        }, error => {
          this.loading = false;
        });
      }, 

      updateUserTier: function(id,params) { 
          AccountService.updateUserTier(id,params).then(resp => {  //console.log(resp);

            if(resp.message=='Success'){
                this.msg   = 'Update User Tier Success'; 
                this.col = 'success';  this.seen  = true; this.seenBtnCcl = false; this.seenBtnUpdate = false; this.seenBtnAdd =true;
                this.getUserTierById(this.id);
              } else {
                this.msg   = !resp.data.message ? "Update User Tier Failed!" : resp.data.message; 
                this.col = 'warning'; this.seen  = true;                  
              }
              setTimeout( () =>  this.seen=false , 5000);            
           }, error => {
            console.log('error');    
          });
      }, 
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>